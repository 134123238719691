import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useCancelOrder } from "@hooks/api/useCaseApi";
import { useRedStore } from "@store/store";
import { showSuccessToast } from "@utils";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { queryClient } from "scripts/reactQuery";
import { Icon } from "@iconify/react";
import { clickOnCancelOrderButton, closeCancelOrderModal, openCancelOrderModal } from "@config/event";

const values = [
  { label: "Long wait time", value: "Long wait time" },
  { label: "High prices ", value: "High prices " },
  { label: "Issue with address", value: "Issue with address" },
  { label: "Not admitted", value: "Not admitted" },
  { label: "Change Vehicle", value: "Change Vehicle" },
  { label: "Vehicle delay", value: "Vehicle delay" },
  { label: "Patient Expired", value: "Patient Expired" },
  { label: "Went in Own Vehicle/Went in cab or Auto", value: "Went in Own Vehicle/Went in cab or Auto" },
  { label: "Competitor Serviced/Went in Private ambulance /Went to another Hospital/Went in 108", value: "Competitor Serviced/Went in Private ambulance /Went to another Hospital/Went in 108" },
  { label: "Customer not responding", value: "Customer not responding" },
  { label: "Price issue", value: "Price issue" },
  { label: "Equipment/Helper/O2/EMT Not available", value: "Equipment/Helper/O2/EMT Not available" },
  { label: "Customer requirement changed", value: "Customer requirement changed" },
  { label: "Duplicate Booking", value: "Duplicate Booking" },
  { label: "Test Booking", value: "Test Booking" },
  { label: "Went in Hospital vehicle", value: "Went in Hospital vehicle" },
  { label: "Union issue", value: "Union issue" },
  { label: "No Info Provided by the Customer", value: "No Info Provided by the Customer" },
  { label: "Bed not available/Treatement Not Avaialble in the Hospital", value: "Bed not available/Treatement Not Avaialble in the Hospital" },
  { label: "Customer requirement postponed", value: "Customer requirement postponed" },
  { label: "Enquired By Mistake", value: "Enquired By Mistake" },
  { label: "Fake Lead", value: "Fake Lead" },
  { label: "Patient stable at the Home/Hospital", value: "Patient stable at the Home/Hospital" },
  { label: "patient is not stable not fit to travell", value: "patient is not stable not fit to travell" },
  { label: "Appointment Cancelled/Postponed/Doctor Not Available", value: "Appointment Cancelled/Postponed/Doctor Not Available" },
  { label: "Appointment Call - Non-Ambulance Query Call", value: "Appointment Call - Non-Ambulance Query Call" },
  { label: "Hospital Emergency Room (ER) Denied ", value: "Hospital Emergency Room (ER) Denied " },
  { label: "Unable to Connect to Hospital Emergency Room (ER) ", value: "Unable to Connect to Hospital Emergency Room (ER) " },
  { label: "Driver Couldn't find the pickup location", value: "Driver Couldn't find the pickup location" },
];

export default function CancelOrder({ ...props }: any) {
  const buttonColorValue = useColorModeValue("#151f21", "gray.900");
  const [selectedReasons, setSelectedReasons]: any = useState({});
  const { cancelNow, ...rest } = useCancelOrder();
  const orderStatus = useRedStore(state => state.order.orderStatus);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id: orderId } = useParams();
  const [searchText, setSearchText] = useState("");

  const handleCancelClick = () => {
    const reasons = Object?.keys(selectedReasons);
    clickOnCancelOrderButton({ orderId: orderId, reasons: reasons });
    cancelNow(reasons, () => {
      showSuccessToast("Order Cancel Successfully.");
      onClose();
      queryClient.invalidateQueries([`case-id-${orderId}`]);
    });
  };

  const handleSelect = ({ label, value }: any) => {
    const obj = JSON.parse(JSON.stringify(selectedReasons));
    if (obj[value]) {
      delete obj[value];
    } else {
      obj[value] = value;
    }

    setSelectedReasons(obj);
  };

  const handleOpen = () => {
    openCancelOrderModal({
      orderId: orderId,
    });
    onOpen();
  };

  const handleClose = () => {
    closeCancelOrderModal({
      orderId: orderId,
    });
    onClose();
  };

  return (
    <Box>
      <Button
        px={4}
        size={"sm"}
        variant={"ghost"}
        rounded={"full"}
        colorScheme="red"
        bg={"white"}
        onClick={handleOpen}
        fontWeight={"semibold"}
        borderWidth={1}
        borderColor={"red.200"}
        w={"full"}
        // isLoading={rest.isLoading}
        isDisabled={["cancelled",'fulfilled'].includes(orderStatus)}
        leftIcon={<Icon icon="flat-color-icons:cancel" fontSize={20} />}
        {...props}
      >
        Cancel Order
      </Button>
      {/* <Button
        onClick={onOpen}
        borderRadius={"md"}
        variant={"solid"}
        size={"xs"}
        fontWeight={"semibold"}
        my={2}
        colorScheme={"red"}
        bg={"red.500"}
        isDisabled={orderStatus == "cancelled"}
        _disabled={{
          bg: "red.300",
          opacity: 0.5,
        }}
      >
        Cancel Order
      </Button> */}
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent mx={5} bg={"white"}>
          <ModalCloseButton />
          <ModalHeader>Cancel Reasons</ModalHeader>
          <Divider />
          <ModalBody>
            <Box h={"50vh"} overflowY={"scroll"} bg={"whiteAlpha.200"} p={2} mx={-2} borderRadius={"md"}>
              <InputGroup size="md" my={2} mb={5}>
                <InputLeftAddon>
                  <Icon icon={"iconamoon:search-bold"} />
                </InputLeftAddon>
                <Input fontSize={"md"} onChange={e => setSearchText(e.target.value)} placeholder="Type Reasons .." />
              </InputGroup>

              <Flex wrap={"wrap"} gap={3}>
                {values
                  ?.filter((item: any) => item?.label?.toString().toLocaleLowerCase()?.includes(searchText))
                  ?.map(itm => (
                    <Tag borderWidth={1} colorScheme={"red"} borderRadius={"md"} px={3} py={1} variant={selectedReasons[itm.value] ? "solid" : "outline"} onClick={() => handleSelect(itm)}>
                      {itm.label}
                    </Tag>
                  ))}
              </Flex>
            </Box>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button onClick={handleCancelClick} isDisabled={Object.keys(selectedReasons)?.length <= 0} borderRadius={"full"} px={6} isLoading={rest?.isLoading} colorScheme="primary" size={"sm"}>
              Cancel Order
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
